import {Component} from 'react';

class SessionHelper extends Component {
    static SetAuthSession(data){
        localStorage.setItem('AuthUser', JSON.stringify(data));
    }

    static GetAuthSession(){
        return JSON.parse(localStorage.getItem('AuthUser'));
    }

    static GetAuthUserId(){
        return this.GetAuthSession() !== null ? this.GetAuthSession().user_id : '';
    }

    static RemoveAuthSession(){
        localStorage.removeItem('AuthUser');
    }

    static SetBranchesSession(data){
        localStorage.setItem('branches', JSON.stringify(data));
    }

    static GetBranchesSession() {
        const branches = localStorage.getItem('branches');
        return branches && branches !== "undefined" ? JSON.parse(branches) : null;
    }
}

export default SessionHelper;