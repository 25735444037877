import React from 'react';
import AppRoute from './routes/AppRoute';
import './_utility/AxiosConfig';
import ModalBody from './component/common/ModalBody';
import { useModal } from './_context/ModalContext';

function App() {
	const { showModal, modalMode, setModalMode, closeModal } = useModal();
	return (
		<>
			<AppRoute />
			<ModalBody
                showModal={showModal}
                modalMode={modalMode}
                setModalMode={setModalMode}
                closeModal={closeModal}
            />
		</>
	);
}

export default App;
