import React, {Component} from 'react';
import {Routes, Route, BrowserRouter} from "react-router-dom";
import Home from "../pages/Home";
import ContactUs from "../pages/ContactUs";
import AboutUs from "../pages/AboutUs";
import Dashboard from "../pages/protected/Dashboard";
import NotFound from "../pages/NotFound";
import Footer from '../component/common/Footer';
import Header from '../component/common/Header';
import RoomDetails from '../pages/rooms/RoomDetails';
import GuestRoutes from '../_utility/GuestRoutes';
import axios from "axios";
import SessionHelper from '../session/SessionHelper';
import RoomBooking from '../pages/rooms/RoomBooking';
import SocialLoginCallback from '../component/auth/partials/SocialLoginCallback';
import AuthRoutes from '../_utility/AuthRoutes';
import BookingConfirm from '../pages/rooms/BookingConfirm';
import Profile from '../pages/protected/Profile';
import ChangePassword from '../pages/protected/ChangePassword';

class AppRoute extends Component {
    componentDidMount() {
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.get('/api/branches').then(res => {
                this.setState({branches:res.data.data});
                SessionHelper.SetBranchesSession(res.data.data);
            }).catch((error)=>{});
        });
    }

    render() {
        return (
            <>
                <BrowserRouter>
                    <Header/>
                    <Routes>
                        <Route path="*" element={<NotFound/>} />
                        <Route path="/" element={<Home />} />
                        <Route path="contact-us" element={<ContactUs />} />
                        <Route path="about-us" element={<AboutUs />} />
                        <Route element={<AuthRoutes/>}>
                            <Route path="dashboard" element={<Dashboard />} />
                            <Route path="profile" element={<Profile />} />
                            <Route path="change/password" element={<ChangePassword />} />
                            <Route path="confirm/:booking_id/booking" element={<BookingConfirm />} />
                        </Route>
                        <Route path="/room/:room_id/details" element={<RoomDetails />} />
                        <Route path="/room/:room_id/booking" element={<RoomBooking />} />
                        <Route element={<GuestRoutes/>}>
                            <Route path="auth/:provider/callback" element={<SocialLoginCallback />} />
                        </Route>

                    </Routes>
                    <Footer/>
                </BrowserRouter>
            </>
        );
    }
}

export default AppRoute;
