import React, { useState } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";

const ContactUs = () => {
    const [inboxInput, setInbox] = useState({
        'name': '',
        'contact_number': '',
        'email': '',
        'subject': '',
        'message': '',
        'errors': [],
        'processing': false
    });

    const handleChange = (e) => {
        setInbox({ ...inboxInput, [e.target.name]: e.target.value });
    }

    const formSubmit = (e) => {
        e.preventDefault();
        setInbox({ ...inboxInput, errors: [], processing: true });
        const data = {
            name: inboxInput.name,
            contact_number: inboxInput.contact_number,
            email: inboxInput.email,
            subject: inboxInput.subject,
            message: inboxInput.message
        }
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post('/api/inbox/store', data).then(res => {
                if (res.data.status === 422) {
                    setInbox({ ...inboxInput, processing: false, errors: res.data.errors });
                }
                else if (res.data.status === 200) {
                    setInbox({
                        ...inboxInput,
                        name: '',
                        contact_number: '',
                        email: '',
                        subject: '',
                        message: '',
                        errors: [],
                        processing: false
                    });
                    Swal.fire('Success!', res.data.message, 'success');
                }
            }).catch((error) => {
                setInbox({ ...inboxInput, processing: false, errors: error.response.data.errors });
                toast.error('Validation unsuccessful.');
            });
        });
    }

    return (
        <>
            <ToastContainer />
            <section>
                <div className="inn-body-section">
                    <div className="container">
                        <div className="page-head">
                            <h2>Contact Us</h2>
                            <div className="head-title">
                                <div className="hl-1"></div>
                                <div className="hl-2"></div>
                                <div className="hl-3"></div>
                            </div>
                            <p>Quisque at volutpat nibh. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                        </div>

                        <div className='row'>
                            <div className='col-sm-4 pr-5'>
                                <div className="mb-3">
                                    <h5 className="font-weight-bold">EMAIL</h5>
                                    <p><a href="mailto:info@younichostel.com">info@younichostel.com</a></p>
                                </div>

                                <div className="mb-3">
                                    <h5 className="font-weight-bold">Branches</h5>

                                    <div className="mb-2">
                                        <h6 className="font-weight-bold text-primary">Dhanmondi Branch:</h6>
                                        <p>251/L, House# 23, Road# 13/A, Dhanmondi, Dhaka</p>
                                        <p><strong>Contact:</strong> 01974 466 434</p>
                                    </div>

                                    <div className="mb-2">
                                        <h6 className="font-weight-bold text-primary">Uttara Branch:</h6>
                                        <p>House# 20, Road# 05, Sector# 05 Uttara, Dhaka, 1230.</p>
                                        <p><strong>Contact:</strong> 01751 592 841</p>
                                    </div>

                                    <div className="mb-2">
                                        <h6 className="font-weight-bold text-primary">Ashulia Branch:</h6>
                                        <p>Khagan Bazar, Birulia, Ashulia, Dhaka</p>
                                        <p><strong>Contact:</strong> 01975 576 484</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-8 d-flex align-items-center justify-content-center'>
                                <form onSubmit={formSubmit} className='w-100'>
                                    <div className="form-group input-field">
                                        <div className="input-field">
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="name"
                                                name="name"
                                                value={inboxInput.name}
                                                onChange={handleChange}
                                                placeholder=''
                                            />
                                            <label htmlFor="name" className='input-field-label'>Your Name</label>
                                        </div>
                                        {inboxInput.errors && inboxInput.errors.name && (
                                            <p className='mt-2'><strong className='text-danger mt-3'>{inboxInput.errors.name}</strong></p>
                                        )}
                                        
                                    </div>

                                    <div className="form-group">
                                        <div className="input-field">
                                            <input
                                                type="tel"
                                                className="form-control form-control-lg"
                                                id="contact_number"
                                                name="contact_number"
                                                value={inboxInput.contact_number}
                                                onChange={handleChange}
                                                placeholder=''
                                            />
                                            <label htmlFor="contact_number" className='input-field-label'>Your Phone No</label>
                                        </div>
                                        {inboxInput.errors && inboxInput.errors.contact_number && (
                                            <p className='mt-2'><strong className='text-danger mt-3'>{inboxInput.errors.contact_number}</strong></p>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <div className="input-field">
                                            <input
                                                type="email"
                                                className="form-control form-control-lg"
                                                id="email"
                                                name="email"
                                                value={inboxInput.email}
                                                onChange={handleChange}
                                                placeholder=''
                                            />
                                            <label htmlFor="email" className='input-field-label'>Your Email</label>
                                        </div>
                                        {inboxInput.errors && inboxInput.errors.email && (
                                            <p className='mt-2'><strong className='text-danger mt-3'>{inboxInput.errors.email}</strong></p>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <div className="input-field">
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="subject"
                                                name="subject"
                                                value={inboxInput.subject}
                                                placeholder=''
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="subject" className='input-field-label'>Subject</label>
                                        </div>
                                        {inboxInput.errors && inboxInput.errors.subject && (
                                            <p className='mt-2'><strong className='text-danger mt-3'>{inboxInput.errors.subject}</strong></p>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <div className="input-field input-field-textarea">
                                            <textarea
                                                className="form-control"
                                                id="message"
                                                rows="4"
                                                name="message"
                                                value={inboxInput.message}
                                                onChange={handleChange}
                                                placeholder=''
                                            ></textarea>
                                            <label htmlFor="message" className='input-field-label'>Message</label>
                                        </div>
                                        {inboxInput.errors && inboxInput.errors.message && (
                                            <p className='mt-2'><strong className='text-danger mt-3'>{inboxInput.errors.message}</strong></p>
                                        )}
                                    </div>

                                    <button
                                        type="submit"
                                        className="btn btn-success btn-lg btn-block"
                                        disabled={inboxInput.processing}
                                    >
                                        {inboxInput.processing ? 'Processing...' : 'Submit'}
                                    </button>
                                </form>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="row">
                    <div className="contact-map">
                    <iframe title='Younic Home' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7303.948859349035!2d90.3701136949866!3d23.748291235068937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755bf00724da8b9%3A0x82cd207efebe07ac!2sYounic%20Home!5e0!3m2!1sen!2sbd!4v1731401256947!5m2!1sen!2sbd" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ContactUs;
