import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import WithRouter from '../../_utility/WithRouter';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SessionHelper from '../../session/SessionHelper';
import { useModal } from '../../_context/ModalContext';
import 'rsuite/dist/rsuite.min.css';
import { DateRangePicker, DatePicker } from 'rsuite';
import OccupantModal from './OccupantModal';
import OccupantCard from './OccupantCard';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useUser } from '../../_context/UserContext';

const RoomBooking = (props) => {
	const room_id = props.params.room_id;
	const { authUserId } = useUser();
	const { openModal } = useModal();
	const [room, setRoom] = useState([]);
	const [roomSeats, setRoomSeats] = useState([]);
	const location = useLocation();
	const navigate = useNavigate();
	const [bookingInfo, setBookingInfo] = useState(() => {
		const initialState = location.state || {};
		return {
			room_id: room_id,
			occupancyType: initialState.occupancyType || '',
			adultCount: initialState.adultCount || 0,
			childrenCount: initialState.childrenCount || 0,
			seatCount: initialState.seatCount || 0,
			dateRange: initialState.dateRange || [null, null],
			date: initialState.date || null,
			isOverlayOpen: false,
			open: false,
			booking_session_id: '',
			auth_user_id: authUserId,
			occupants: [],
			currentSeatId: null,
			currentRoomId: null,
			currentOccupant: null,
			showOccupantModal: false,
			total_amount: 0,
			error: '',
			errors: [],
        	processing: false
		};
	});

	const findSeatById = (seatId) => {
		return roomSeats
		  .flatMap((room) => room.seats) // Flatten all seats
		  .find((seat) => seat.id === seatId); // Find the seat
	  };

	const pickerRef = useRef(null);

	const updateState = (field, value) => {
		setBookingInfo((prevState) => {
			const updatedState = {
				...prevState,
				[field]: value,
			};

			if (field === 'occupancyType') {
				if (value === "Permanent") {
					updatedState.adultCount = 0;
					updatedState.childrenCount = 0;
					updatedState.dateRange = [null, null];
				} else if (value === "Guest") {
					updatedState.date = null;
				}
			}

			return updatedState;
		});
	};

	const increment = (key) => {
		setBookingInfo((prev) => ({
			...prev,
			[key]: prev[key] + 1,
		}));
	};

	const decrement = (key) => {
		setBookingInfo((prev) => ({
			...prev,
			[key]: prev[key] > 0 ? prev[key] - 1 : 0,
		}));
	};

	useEffect(() => {
        setBookingInfo(prevState => ({
            ...prevState,
            auth_user_id: authUserId
        }));
    }, [authUserId]);

	useEffect(() => {
		if (bookingInfo.date || bookingInfo.dateRange || bookingInfo.seatCount || bookingInfo.occupancyType) {
			axios.get('/sanctum/csrf-cookie').then(() => {
				axios.post(`/api/booking/seats/query`, bookingInfo)
					.then(res => {
						setRoomSeats(res.data.data);
						setBookingInfo({ ...bookingInfo, booking_session_id: res.data.booking_session_id });
					})
					.catch(error => {
						console.error("Error fetching rooms:", error);
					});
			});
		}
	}, [bookingInfo.date, bookingInfo.dateRange, bookingInfo.seatCount, bookingInfo.occupancyType]);

	useEffect(() => {
		window.scrollTo(0, 0);
		axios.get('/sanctum/csrf-cookie').then(() => {
			axios.get(`/api/room/${room_id}/details`)
				.then(res => {
					const data = res.data.data;
					setRoom(data);
				})
				.catch(error => {
					console.error("Error fetching rooms:", error);
				});
		});
	}, [room_id]);

	const calculateSeatAmount = (seat) =>
		bookingInfo.occupancyType === 'Permanent' ? parseFloat(seat.price_per_month) : parseFloat(seat.price_per_night);

	const handleCheckboxChange = (seatId, roomId, checked) => {
		const seat = findSeatById(seatId); // Find the seat details
  		const seatAmount = calculateSeatAmount(seat);
		setBookingInfo(prev => {
			if (!checked) {
				const updatedOccupants = prev.occupants.filter(
					(occupant) => occupant.seat_id !== seatId
				);
				return {
					...prev,
					occupants: updatedOccupants,
					total_amount: bookingInfo.total_amount - seatAmount,
					errors: [], 
					error: '', 
					processing: false,
				};
			} else {
				return {
					...prev,
					currentSeatId: seatId,
					currentRoomId: roomId,
					showOccupantModal: true, // Trigger modal
					errors: [], 
					error: '', 
					processing: false,
				};
			}
		});
	};

	const handleAddEditOccupant = (seatId, roomId, occupant) => {
		setBookingInfo((prev) => ({
			...prev,
			currentSeatId: seatId,
			currentRoomId: roomId,
			currentOccupant: occupant,
			showOccupantModal: true,
			errors: [], 
			error: '', 
			processing: false,
		}));
	};

	const saveBooking = (occupantData = [], finalSave) => {
		setBookingInfo((prev) => {
			return {
				...prev,
				errors: [], 
				error: '', 
				processing: true
			};
		  });

		  const newOccupants = occupantData && Object.keys(occupantData).length > 0
		  ? [
			  ...bookingInfo.occupants.filter((occupant) => occupant.seat_id !== occupantData.seatId),
			  {
				id: occupantData?.id || '',
				name: occupantData?.name || '',
				email: occupantData?.email || '',
				contact_number: occupantData?.contact_number || '',
				gender: occupantData?.gender || '',
				room_id: occupantData?.roomId || '',
				seat_id: occupantData?.seatId || '',
				dob: occupantData?.dob || '',
				address: occupantData?.address || '',
				nationality: occupantData?.nationality || '',
				identification_type: occupantData?.identification_type || '',
				identification_number: occupantData?.identification_number || '',
			  }
		  ]
		  : bookingInfo.occupants;

		const bookingData = {
			room_id: bookingInfo.room_id,
			occupancyType: bookingInfo.occupancyType,
			adultCount: bookingInfo.adultCount,
			childrenCount: bookingInfo.childrenCount,
			seatCount: bookingInfo.seatCount,
			dateRange: bookingInfo.dateRange,
			date: bookingInfo.date,
			auth_user_id: bookingInfo.auth_user_id,
			booking_session_id: bookingInfo.booking_session_id,
			occupants: newOccupants,
			finalSave: finalSave,
		};
		axios.get('/sanctum/csrf-cookie').then(() => {
			axios.post(`/api/store/booking`, bookingData)
			.then((res) => {
				setBookingInfo({ 
					...bookingInfo, 
					errors: [], 
					error: '', 
					processing: false,
					booking_session_id: res.data.data.booking.session_id,
					occupants: res.data.data.occupants,
					// ForOccupantModal
					currentSeatId: null,
					currentRoomId: null,
					currentOccupant: null,
			  		showOccupantModal: false,
				});
				
				if(res.data.data.booking.status === 'Pending')
				{
					navigate(`/confirm/${res.data.data.booking.id}/booking`,{
						state: {
							booking_id:res.data.data.booking.id,
						},
					});
				}
			})
			.catch((res) => {
				console.log(res.response.data.errors);
				setBookingInfo({
					...bookingInfo, 
					errors: res.response.data.errors, 
					error: ['Error occurred while processing your booking. Please try again.'], 
					processing: false,
				});
			});
		});
	};

	return (
		<>
			<section>
				<div className="inn-body-section">
					<div className="container mt-5">
							<div className="row">
								<div className="col-sm-12 confirm_booking_wrapper">
									<Link to={`/room/${room.id}/details`} className='confirm_booking_title'><i className="fa fa-chevron-left"></i> Confirm booking and pay</Link>

									<div className='form-group mb-3 confirm_booking_info border-bottom'>
										<h3>Your Booking</h3>
										<div className='d-flex justify-content-between w-100'>
											<div className='booking_info_item'>
												<strong>Branch</strong>
												{room.apartment?.branch?.name}
											</div>
										</div>

										<div className='d-flex justify-content-between w-100'>
											<div className='booking_info_item'>
												<strong>Address</strong>
												{room.apartment?.address}
											</div>
										</div>

										<div className='d-flex justify-content-between w-100'>
											<div className='booking_info_item'>
												<strong>Occupancy</strong>
												{bookingInfo.occupancyType}
											</div>
											<div className='booking_info_edit_btn'>
												<button
													className='btn btn-light'
													onClick={() => {
														if (room.occupancy_type === 'Both') {
															updateState(
																'occupancyType',
																bookingInfo.occupancyType === 'Permanent' ? 'Guest' : 'Permanent'
															);
														}
													}}
													disabled={room.occupancy_type !== 'Both'}
												>
													<i className='fa fa-refresh'></i>
												</button>
											</div>
										</div>
										<div className='d-flex justify-content-between w-100' ref={pickerRef} style={{ 'position': 'relative' }}>
											<div className='booking_info_item'>
												<strong>Dates</strong>
												{bookingInfo.occupancyType === 'Permanent' ? (
													<>
														{bookingInfo.date ? new Date(bookingInfo.date).toLocaleDateString() : 'Check In'}
													</>
												) : (
													<>
														<span>
															{
																bookingInfo.dateRange && bookingInfo.dateRange[0] 
																? new Date(bookingInfo.dateRange[0]).toLocaleDateString() 
																: 'Check In'
															}
															<span> - </span>
															{
																bookingInfo.dateRange && bookingInfo.dateRange[1] 
																? new Date(bookingInfo.dateRange[1]).toLocaleDateString() 
																: 'Check Out'
															}
														</span>
													</>
												)}
											</div>
											<div className='booking_info_edit_btn'>
												<button className='btn btn-light' onClick={() => updateState('open', true)}>Edit</button>
											</div>
											<div className='daterangePicker'>
												{bookingInfo.occupancyType === 'Permanent' ? (
													<>
														{bookingInfo.open && (
															<DatePicker
																value={bookingInfo.date}
																onChange={(value) => updateState('date', value)}
																open={bookingInfo.open}
																onClose={() => updateState('open', false)}
																placement="bottomStart"
																label={false}
															/>
														)}
													</>
												) : (
													<>
														{bookingInfo.open && (
															<DateRangePicker
																value={bookingInfo.dateRange}
																onChange={(value) => updateState('dateRange', value)}
																open={bookingInfo.open}
																onClose={() => updateState('open', false)}
																placement="bottomStart"
																label={false}
															/>
														)}
													</>
												)}
											</div>
										</div>
										{bookingInfo.occupancyType === 'Permanent' ? (
											<>
												<div className='d-flex justify-content-between w-100'>
													<div className='booking_info_item'>
														<strong>Seats</strong>
														<span>
															{bookingInfo.seatCount >= 0 && (
																<span>
																	{bookingInfo.seatCount} Seat{bookingInfo.seatCount > 1 ? 's' : ''}
																</span>
															)}
														</span>
													</div>
													<div className='booking_info_edit_btn'>
														<div className='input_counter pull-right d-flex'>
															<button onClick={() => decrement('seatCount')}>-</button>
															<strong className='counter mr-2 ml-2'>{bookingInfo.seatCount}</strong>
															<button onClick={() => increment('seatCount')}>+</button>
														</div>
													</div>
												</div>
											</>
										) : (
											<>
												<div className='d-flex justify-content-between w-100'>
													<div className='booking_info_item'>
														<strong>Adults</strong>
														<span>
															{bookingInfo.adultCount >= 0 && (
																<span>
																	{bookingInfo.adultCount} Adult{bookingInfo.adultCount > 1 ? 's' : ''}
																</span>
															)}
														</span>
													</div>
													<div className='booking_info_edit_btn'>
														<div className='input_counter pull-right d-flex'>
															<button onClick={() => decrement('adultCount')}>-</button>
															<strong className='counter mr-2 ml-2'>{bookingInfo.adultCount}</strong>
															<button onClick={() => increment('adultCount')}>+</button>
														</div>
													</div>
												</div>
												<div className='d-flex justify-content-between w-100'>
													<div className='booking_info_item'>
														<strong>Childrens</strong>
														<span>
															{bookingInfo.childrenCount >= 0 && (
																<span>
																	{bookingInfo.childrenCount} Child{bookingInfo.childrenCount > 1 ? 'ren' : ''}
																</span>
															)}
														</span>
													</div>
													<div className='booking_info_edit_btn'>
														<div className='input_counter pull-right d-flex'>
															<button onClick={() => decrement('childrenCount')}>-</button>
															<strong className='counter mr-2 ml-2'>{bookingInfo.childrenCount}</strong>
															<button onClick={() => increment('childrenCount')}>+</button>
														</div>
													</div>
												</div>
												<div className='d-flex justify-content-between w-100'>
													<div className='booking_info_item'>
														<strong>Seats</strong>
														<span>
															{bookingInfo.seatCount >= 0 && (
																<span>
																	{bookingInfo.seatCount} Seat{bookingInfo.seatCount > 1 ? 's' : ''}
																</span>
															)}
														</span>
													</div>
													<div className='booking_info_edit_btn'>
														<div className='input_counter pull-right d-flex'>
															<button onClick={() => decrement('seatCount')}>-</button>
															<strong className='counter mr-2 ml-2'>{bookingInfo.seatCount}</strong>
															<button onClick={() => increment('seatCount')}>+</button>
														</div>
													</div>
												</div>
											</>
										)}

										<div className='d-flex justify-content-between w-100'>
											<div className='booking_info_item'>
												<strong>Total (BDT)</strong>
												<strong>৳{bookingInfo.total_amount}</strong>
											</div>
										</div>
									</div>

									<div className='form-group confirm_booking_info'>
										<h3>Select Seats</h3>

										{
											roomSeats.map((room, index) => (
												<>
													<div className='form-group'>
														<div style={{ "display": "grid", "grid-template-columns": "200px 200px 1fr", "gap": "1rem" }}>
															<div className='d-flex flex-column justify-content-center'>
																{room.featured_image && room.featured_image.length > 0 ? (
																	<img
																		src={room.featured_image[0].data_url}
																		alt={room.title}
																		className='rounded mt-2 mb-2'
																		style={{ 'maxWidth': '150px' }}
																	/>
																) : (
																	<img
																		src="/assets/images/room/4.jpg"
																		alt="Room"
																		className='rounded mt-2 mb-2'
																		style={{ 'maxWidth': '150px' }}
																	/>
																)}
															</div>
															<div className='mr-5 d-flex flex-column justify-content-center'>
																<p><strong>{room.title}</strong></p>
																<p>
																	<span className='d-inline-block mr-2'><strong className='text-primary'>{room.total_seats} Seats</strong></span>
																	<span className='d-inline-block'><strong className='text-success'>{room.available_seats} Available</strong></span>
																</p>
															</div>
															<div className='d-flex flex-column justify-content-center'>
															<div class="grid-container" style={{ "display": "grid","grid-template-columns": "repeat(auto-fit, minmax(220px, 1fr))","gap": "1rem" }}>

																
																{
																	room.seats.map((seat, index) => (
																		<div key={index} className='grid-item'>
																			<div className="form-check form-check-inline">
																				<input
																					type="checkbox"
																					id={`custom-checkbox-${seat.id}`}
																					className="form-check-input"
																					checked={bookingInfo.occupants.some((occupant) => occupant.seat_id === seat.id)}
																					onChange={(e) => handleCheckboxChange(seat.id, room.id, e.target.checked)}
																				/>
																				<label title="" for={`custom-checkbox-${seat.id}`} className="form-check-label">
																					<p className="text-primary">{seat.seat_type} Seat</p>
																					<span className="text-black">
																						৳{bookingInfo.occupancyType === 'Permanent' ? seat.price_per_month : seat.price_per_night} per {bookingInfo.occupancyType === 'Permanent' ? "month" : "night"}
																					</span>
																				</label>
																			</div>
																		</div>
																	))
																}
																</div>
															</div>
														</div>
														<div className="occupants-group mt-2" id={`occupants_for_room_${room.id}`}>
															{bookingInfo.occupants
																.filter((occupant) =>
																	room.seats.some((seat) => String(seat.id) === String(occupant.seat_id)) &&
																	String(occupant.room_id) === String(room.id)
																)
																.map((occupant) => (
																	<>
																		<OccupantCard
																			key={occupant.seat_id}
																			occupant={occupant}
																			onClick={() => handleAddEditOccupant(occupant.seat_id, occupant.room_id, occupant)}
																		/>
																	</>
																))}
														</div>
													</div>
												</>
											))
										}

										<OccupantModal
											showOccupantModal={bookingInfo.showOccupantModal}
											onClose={() => setBookingInfo(prev => ({ ...prev, showOccupantModal: false }))}
											seatId={bookingInfo.currentSeatId}
											roomId={bookingInfo.currentRoomId}
											currentOccupant={bookingInfo.currentOccupant}
											onSave={saveBooking}
											errors={bookingInfo.errors}
											processing={bookingInfo.processing}
										/>
									</div>

									{bookingInfo?.errors?.occupants && typeof bookingInfo.errors.occupants !== 'object' && (
										<div className="alert alert-danger" role="alert">
											{bookingInfo.errors.occupants}
										</div>
									)}

									{bookingInfo?.errors && bookingInfo?.errors?.auth_required && (
										<div class="alert alert-danger" role="alert">{bookingInfo?.errors?.auth_required}</div>
									)}

									<div className='form-group'>
										{SessionHelper.GetAuthSession() !== null ? (
											<>
												<button
													className="btn btn-success btn-block"
													onClick={() => saveBooking([], true)}
													type="button"
													disabled={bookingInfo.processing}
												>
													{bookingInfo.processing ? 'Processing...' : 'Book Now'}
												</button>
											</>
										) : (
											<>
												<button className='btn btn-success btn-block' onClick={() => openModal('register')} type='button'>Sign In for Booking</button>
											</>
										)}
									</div>
								</div>
							</div>
					</div>

				</div>
			</section>
		</>
	);
}

export default WithRouter(RoomBooking);
