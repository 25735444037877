import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import WithRouter from '../../_utility/WithRouter';
import { useNavigate } from 'react-router';

const BookingConfirm = (props) => {
    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
    
    const booking_id = props.params.booking_id;
    const navigate = useNavigate();
    const [bookingInput, setBooking] = useState({
        'booking_id': booking_id,
        'payment_method': '',
        'errors': [],
        'processing': false
    });

    const [bookingInfo, setBookingInfo] = useState([]);

    useEffect(() => {
        axios.get('/sanctum/csrf-cookie').then(() => {
            axios.post(`/api/booking/${booking_id}/details`, bookingInfo)
                .then(res => {
                    setBookingInfo(res.data.data);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        });
    }, [booking_id]);

    const handleChange = (e) => {
        setBooking({ ...bookingInput, [e.target.name]: e.target.value });
    }

    const formSubmit = (e) => {
        e.preventDefault();
        setBooking({ ...bookingInput, errors: [], processing: true });
        const data = {
            payment_method: bookingInput.payment_method,
            booking_id: bookingInput.booking_id
        }
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/confirm/${booking_id}`, data).then(res => {
                if (res.data.status === 422) {
                    setBooking({ ...bookingInput, processing: false, errors: res.data.errors });
                }
                else if (res.data.status === 200) {
                    setBooking({
                        ...bookingInput,
                        payment_method: '',
                        errors: [],
                        processing: false
                    });
                    Swal.fire('Success!', res.data.message, 'success');
                    navigate('/dashboard')
                }
            }).catch((error) => {
                setBooking({ ...bookingInput, processing: false, errors: error.response.data.errors });
                toast.error('Validation unsuccessful.');
            });
        });
    }

    return (
        <>
            <ToastContainer />
            <section>
                <div className="inn-body-section">
                    <div className="container">
                        <div className="page-head">
                            <h2>Confirm Your Booking</h2>
                            <div className="head-title">
                                <div className="hl-1"></div>
                                <div className="hl-2"></div>
                                <div className="hl-3"></div>
                            </div>
                            <p>Quisque at volutpat nibh. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                        </div>
                        <div className='row'>
                            <div className='col-md-8 offset-md-2'>
                                <div className='shadow_card'>
                                    <div className='form-group mb-3'>
                                        <div className='form-group'>
                                            <strong>Branch: </strong>
                                            {bookingInfo?.apartment?.branch?.name}
                                        </div>

                                        <div className='form-group'>
                                            <strong>Address: </strong>
                                            {bookingInfo?.apartment?.address}
                                        </div>

                                        <div className='form-group'>
                                            <strong>Occupancy: </strong>
                                            {bookingInfo?.occupancy_type}
                                        </div>
                                        <div className='form-group'>
                                            <strong>Dates: </strong>
                                            {bookingInfo?.occupancyType === 'Permanent' ? (
                                                <>
                                                    {bookingInfo?.check_in_date ? new Date(bookingInfo?.check_in_date).toLocaleDateString() : 'Check In'}
                                                </>
                                            ) : (
                                                <>
                                                    <span>
                                                    {bookingInfo?.check_in_date ? new Date(bookingInfo?.check_in_date).toLocaleDateString() : 'Check In'} - {bookingInfo?.check_in_date ? new Date(bookingInfo?.check_out_date).toLocaleDateString() : 'Check Out'}
                                                    </span>
                                                </>
                                            )}
                                        </div>

                                        <div className='form-group'>
                                            {bookingInfo?.occupancyType === 'Permanent' ? (
                                                <>
                                                    <strong>Seats</strong>
                                                    <span>{bookingInfo?.number_of_seats}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <strong>
                                                        {bookingInfo?.total_adults > 0 && <span className='text-black' style={{ 'fontSize':'16px' }}>{bookingInfo?.total_adults} Adult{bookingInfo?.total_adults > 1 ? 's' : ''} </span>}
                                                        {bookingInfo?.total_children > 0 && <span className='text-black' style={{ 'fontSize':'16px' }}>{bookingInfo?.total_children} Child{bookingInfo?.total_children > 1 ? 'ren' : ''} </span>}
                                                        {bookingInfo?.number_of_seats > 0 && <span className='text-black' style={{ 'fontSize':'16px' }}>{bookingInfo?.number_of_seats} Seat{bookingInfo?.number_of_seats > 1 ? 's' : ''} </span>}
                                                    </strong>
                                                </>
                                            )}
                                        </div>

                                        <div className='d-flex justify-content-between w-100'>
                                            <div className='form-group'>
                                                <strong>Total (BDT): </strong>
                                                <strong>৳{bookingInfo?.total_amount}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={formSubmit} className='w-100'>

                                        <div className="form-group">
                                            <div className="input-field">
                                                <select
                                                    name="payment_method"
                                                    value={bookingInput.payment_method}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    >
                                                        <option value="">Select Payment Method</option>
                                                        <option value="Cash">Cash</option>
                                                        <option value="Card">Bkash</option>
                                                    </select>
                                            </div>
                                            {bookingInput.errors && bookingInput.errors.subject && (
                                                <p className='mt-2'><strong className='text-danger mt-3'>{bookingInput.errors.subject}</strong></p>
                                            )}
                                        </div>

                                        <button
                                            type="submit"
                                            className="btn btn-success btn-lg btn-block"
                                            disabled={bookingInput.processing}
                                        >
                                            {bookingInput.processing ? 'Processing...' : 'Confirm Booking'}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default WithRouter(BookingConfirm);
