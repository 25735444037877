import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../../_context/UserContext';
import Sidebar from './partials/Sidebar';
import CountrySelector from '../../component/partials/CountrySelector';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast, ToastContainer } from 'react-toastify';
import SessionHelper from '../../session/SessionHelper';
import WithRouter from '../../_utility/WithRouter';

const ChangePassword = () => {
    const { authUser } = useUser();
    const [formData, setFormData] = useState({
        user_id: authUser?.user_id,
        current_password: '',
        new_password: '',
        confirm_password: '',
        message: '',
        error: '',
        errors: [],
        processing: false
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        setFormData({ ...formData, error:'', message: '', errors: [], processing: true });
        const data = {
            user_id: formData.user_id,
            current_password: formData.current_password,
            new_password: formData.new_password,
            confirm_password: formData.confirm_password,
        }
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post('/api/update/profile/password', data).then(res => {
                if (res.data.status === 400) {
                    setFormData({ ...formData, processing: false, error: res.data.message, errors: res.data.errors });
                }
                else if (res.data.status === 200) {
                    setFormData({
                        ...formData,
                        current_password: '',
                        new_password: '',
                        confirm_password: '',
                        error: '',
                        message: '',
                        errors: [],
                        processing: false
                    });
                    Swal.fire('Success!', res.data.message, 'success');
                }
            }).catch((error) => {
                setFormData({ ...formData, message: '', error: error?.response?.data?.message, processing: false, errors: error?.response?.data?.errors });
                toast.error('Validation unsuccessful.');
            });
        });
    }


    return (
        <>
            <section>
            <ToastContainer />
                <div className="dashboard mt-0 mb-0">
                    <div className='row'>
                        <div className="col-lg-3 col-sm-12 db-left p-0">
                            <Sidebar />
                        </div>
                        <div className="col-lg-9 col-sm-12 db-cent p-0">
                            <div class="db-cent-1">
                                <p>Hi {authUser?.detail?.name},</p>
                                <h4>Welcome to your profile</h4> </div>
                            <div class="db-profile">
                                <img src="/assets/images/profile.png" alt="" />
                                <h4>{authUser?.detail?.name}</h4>
                                <p>{authUser?.detail?.address}</p>
                            </div>
                            <div class="db-profile-edit">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <div className="input-field">
                                            <input
                                                name="current_password"
                                                id="current_password"
                                                value={formData.current_password}
                                                onChange={handleChange}
                                                className="form-control"
                                                placeholder=''
                                            />
                                            <label htmlFor='current_password' className='input-field-label'>Current Password</label>
                                        </div>

                                        {formData.errors && formData.errors.current_password && (
                                            <p className='mt-2 text-left'><strong className='text-danger mt-3'>
                                                {formData.errors.current_password}
                                            </strong>
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <div className="input-field">
                                            <input
                                                name="new_password"
                                                id="new_password"
                                                value={formData.new_password}
                                                onChange={handleChange}
                                                className="form-control"
                                                placeholder=''
                                            />
                                            <label htmlFor='new_password' className='input-field-label'>New Password</label>
                                        </div>

                                        {formData.errors && formData.errors.new_password && (
                                            <p className='mt-2 text-left'><strong className='text-danger mt-3'>
                                                {formData.errors.new_password}
                                            </strong>
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <div className="input-field">
                                            <input
                                                name="confirm_password"
                                                id="confirm_password"
                                                value={formData.confirm_password}
                                                onChange={handleChange}
                                                className="form-control"
                                                placeholder=''
                                            />
                                            <label htmlFor='confirm_password' className='input-field-label'>Confirm Password</label>
                                        </div>

                                        {formData.errors && formData.errors.confirm_password && (
                                            <p className='mt-2 text-left'><strong className='text-danger mt-3'>
                                                {formData.errors.confirm_password}
                                            </strong>
                                            </p>
                                        )}

                                    </div>
                                    {formData.error && (
										<div class="alert alert-danger" role="alert">{formData.error}</div>
									)}
                                    <button
                                        className="waves-effect waves-light pro-sub-btn"
                                        id="pro-sub-btn"
                                        type="submit"
                                        disabled={formData.processing}
                                    >
                                        {formData.processing}
                                        {formData.processing ? 'Processing...' : 'Update Password'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default WithRouter(ChangePassword);
