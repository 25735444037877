import React, { createContext, useContext, useState } from 'react';
import SessionHelper from '../session/SessionHelper';
import axios from 'axios';
// import Swal from 'sweetalert2';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [authUserId, setAuthUserId] = useState(SessionHelper.GetAuthSession()?.user_id ?? '');
    const [authUser, setAuthUser] = useState(SessionHelper.GetAuthSession() ?? null);

    const logout = async (e) => {
        e?.preventDefault();
        try {
            await axios.get('/sanctum/csrf-cookie');
            const response = await axios.post('/api/logout');

            if (response.data.status === 200) {
                setAuthUserId('');
                setAuthUser(null);
                SessionHelper.RemoveAuthSession();
                // Swal.fire('Success!', response.data.message, 'success');
                window.location.href = process.env.REACT_APP_CLIENT_DOMAIN;
            } else {
                // Swal.fire('Logout failed!', response.data.message, 'error');
            }
        } catch (error) {
            // Swal.fire('Error!', error.response?.data || error.message, 'error');
        }
    };

    return (
        <UserContext.Provider value={{ authUserId, setAuthUserId, authUser, setAuthUser, logout }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    return useContext(UserContext);
};