import React, { useEffect, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import axios from 'axios';
import Swal from "sweetalert2";
import SessionHelper from "../../../session/SessionHelper";
import SocialLogin from "./SocialLogin";
import { useUser } from "../../../_context/UserContext";

const Register = ({ closeModal, setModalMode }) => {
    const { setAuthUserId, setAuthUser } = useUser();
    const [formData, setFormData] = useState({
        emailOrPhone: "",
        password: "",
        confirm_password: "",
        loading: false,
        error: '',
        errors: [],
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormData({ ...formData, errors: [], error: '', loading: true });
    
        try {
            // Get the CSRF cookie
            await axios.get('/sanctum/csrf-cookie');
    
            // Post registration data
            const response = await axios.post('/api/register', formData);
    
            if (response.data.status === 400) {
                setFormData({
                    ...formData,
                    errors: response.data.errors,
                    error: response.data.message,
                    loading: false,
                });
            } else if (response.data.status === 200) {
                SessionHelper.SetAuthSession(response.data.user);
                setAuthUser(response.data.user);
                setAuthUserId(response.data.user.user_id);
                setFormData({
                    ...formData,
                    emailOrPhone: "",
                    password: "",
                    confirm_password: "",
                    loading: false,
                    error: '',
                    errors: [],
                });
                closeModal();
                Swal.fire('Success!', response.data.message, 'success');
            }
        } catch (error) {
            // Extract a meaningful error message
            const errorMessage =
                error.response?.data?.message || // Server provided message
                error.message ||                // Axios error message
                'An unknown error occurred.';  // Default fallback
    
            setFormData({
                ...formData,
                error: errorMessage, // Set a string error message
                loading: false,
            });
    
            closeModal();
            Swal.fire('Error!', errorMessage, 'error');
        }
    };
    

    return (

        <>
            <Form onSubmit={handleSubmit}>
                <div className="log-in-pop-left">
                    <div style={{ 'position':'relative', 'zIndex':'10' }}> 
                        <h1>Hello...</h1>
                        <p>Don't have an account? Create your account. It's take less then a minutes</p>
                        <h4>Login with social media</h4>
                        <ul className='list-unstyled'>
                            <SocialLogin setModalMode={setModalMode}/>
                        </ul>
                    </div>
                </div>
                <div className="log-in-pop-right">
                    <a href="#" className="pop-close" onClick={closeModal}><img style={{ width: '15px' }} src="/assets/images/cancel.png" alt="" />
                    </a>
                    <h4>Create an Account</h4>
                    <p>Don't have an account? Create your account. It's take less then a minutes</p>
                    <div className='form-group'>
                        <div className="input-field">
                            <input type="text" 
                                id='emailOrPhone' 
                                name='emailOrPhone' 
                                autoComplete="" 
                                className="form-control form-control-lg" 
                                value={formData.emailOrPhone}
                                onChange={handleInputChange}
                                placeholder='' 
                            />
                            <label htmlFor='emailOrPhone' className='input-field-label'>Email or Mobile</label>
                        </div>
                        {formData.errors && formData.errors.emailOrPhone && (
                            <p className='mt-2'><strong className='text-danger mt-3'>{formData.errors.emailOrPhone}</strong></p>
                        )}
                    </div>
                    <div className='form-group'>
                        <div className="input-field">
                            <input 
                                type="password" 
                                id='password' 
                                name='password' 
                                className="form-control form-control-lg" 
                                value={formData.password}
                                onChange={handleInputChange}
                                placeholder='' 
                            />
                            <label htmlFor='password' className='input-field-label'>Password</label>
                        </div>
                        {formData.errors && formData.errors.password && (
                            <p className='mt-2'><strong className='text-danger mt-3'>{formData.errors.password}</strong></p>
                        )}
                    </div>
                    <div className='form-group'>
                        <div className="input-field">
                            <input 
                                type="password" 
                                id='confirm-password' 
                                name='confirm_password' 
                                className="form-control form-control-lg"
                                value={formData.confirm_password}
                                onChange={handleInputChange}
                                placeholder='' 
                            />
                            <label htmlFor='confirm-password' className='input-field-label'>Confirm password</label>
                        </div>
                        {formData.errors && formData.errors.confirm_password && (
                            <p className='mt-2'><strong className='text-danger mt-3'>{formData.errors.confirm_password}</strong></p>
                        )}
                    </div>
                    <div className='form-group'>
                    {formData.error && <Alert variant="danger">{formData.error}</Alert>}
                        <div className="input-field">
                            <Button variant="primary" type="submit" disabled={formData.loading}>
                                {formData.loading ? "Processing..." : "Register"}
                            </Button>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="input-field"> <a href="#" onClick={() => setModalMode("login")}>Are you a already member ? Login</a> </div>
                    </div>
                </div>
            </Form>
        </>
    );
}

export default Register;
