import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import useBranches from '../../../data/useBranches';

const AutosuggestInput = () => {
    const [searchValue, setSearchValue] = useState("");  // Using `searchValue` and `setSearchValue`
    const [suggestedItems, setSuggestedItems] = useState([]);

    const branches = useBranches();

    const suggestions = branches.map(branch => ({
        name: branch.name,
        content: `<p><strong>${branch.name}</strong></p><p>${branch.address}</p>`
    }));

    // Custom rendering for suggestions
    const renderSuggestion = (suggestion) => (
        <div dangerouslySetInnerHTML={{ __html: suggestion.content }} />
    );

    const getSuggestionValue = (suggestion) => suggestion.name;

    const onSuggestionsFetchRequested = ({ value }) => {
        const filteredSuggestions = suggestions.filter(suggestion =>
            suggestion.name.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestedItems(filteredSuggestions);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestedItems([]);
    };

    return (
        <Autosuggest
            suggestions={suggestedItems}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
                placeholder: "Search here....",
                value: searchValue,  // Updated to use `searchValue`
                onChange: (_, { newValue }) => setSearchValue(newValue)  // Updated to use `setSearchValue`
            }}
        />
    );
};

export default AutosuggestInput;