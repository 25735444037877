import React, {useState, useEffect} from 'react';
import axios from "axios";

function SocialLogin({setAuthModalMode}) {
    const [googleLoginUrl, setGoogleLoginUrl] = useState(null);
    const [facebookLoginUrl, setFacebookLoginUrl] = useState(null);
    const [instagramLoginUrl, setInstagramLoginUrl] = useState(null);

    useEffect(() => {
        if (!facebookLoginUrl || !instagramLoginUrl || !googleLoginUrl) {
            axios.get('/sanctum/csrf-cookie').then(() => {
                Promise.all([
                    axios.post('/api/auth/facebook'),
                    axios.post('/api/auth/instagram'),
                    axios.post('/api/auth/google')
                ])
                .then(([facebookRes, instagramRes, googleRes]) => {
                    setFacebookLoginUrl(facebookRes.data.url);
                    setInstagramLoginUrl(instagramRes.data.url);
                    setGoogleLoginUrl(googleRes.data.url);
                })
                .catch((error) => {
                    console.error('Error fetching social login URLs:', error);
                });
            });
        }
    }, [facebookLoginUrl, instagramLoginUrl, googleLoginUrl]);

    return (
        <>
            <li><a href={facebookLoginUrl || "#"}><i className="fa fa-facebook"></i> Facebook</a></li>
            <li><a href={instagramLoginUrl || "#"}><i className="fa fa-instagram"></i> Instagram</a></li>
            <li><a href={googleLoginUrl || "#"}><i className="fa fa-google"></i> Google</a></li>
        </>
    );
}

export default SocialLogin;