import React, { Component } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
const options = {
    loop: true,
    nav: true,
    margin: 10,
    items: 1,
    navContainer: false,
    navElement: "div",
    dots: true,
};

class RoomGrid extends Component {
    state = {
        rooms: []
    };

    generateRandomRating = () => {
        const min = 4.5;
        const max = 5.0;
        const step = 0.1;
        const randomValue = Math.round((Math.random() * (max - min) + min) / step) * step;
        return randomValue.toFixed(1); // Ensuring 1 decimal place
    };

    componentDidMount() {
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.get('/api/rooms').then(res => {
                this.setState({ rooms: res.data.data });
                // console.log(res.data.data);
            }).catch(error => {
                console.error("Error fetching rooms:", error);
            });
        });
    }

    render() {
        return (
            <>
                <div className='room_card_wrapper'>
                    {this.state.rooms && this.state.rooms.length > 0 ? (
                        this.state.rooms.map((room, index) => (
                            <>
                                <div className="room_card_single" key={index}>
                                    <div className='room-card card border-0'>
                                        <div className='card-img-top'>
                                            <OwlCarousel className="room_card_image_slider owl-carousel owl-theme" {...options}>
                                                <div className="item">
                                                    <Link to={`/room/${room.id}/details`}>
                                                        <img
                                                            src={room.featured_image[0].data_url}
                                                            alt={room.title}
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="item">
                                                    <Link to={`/room/${room.id}/details`}>
                                                        <img
                                                            src={room.featured_image[0].data_url}
                                                            alt={room.title}
                                                        />
                                                    </Link>
                                                </div>
                                            </OwlCarousel>
                                        </div>
                                        <div className="card-body room_card_details p-0">
                                            <Link to={`/room/${room.id}/details`}>
                                                <div className='room_title'>
                                                    <span>{room.title}</span>
                                                    <span className='room_rating'><i className="fa fa-star"></i> {this.generateRandomRating()}</span>
                                                </div>
                                                <p className='text-muted room_address'>{room.apartment.address}</p>
                                                <p className='room_price m-0'>
                                                    {room.price_per_months ? (
                                                        <strong className="text-decoration-underline">৳{room.price_per_months}</strong>
                                                    ) : (
                                                        <strong className="text-decoration-underline">৳{room.price_per_night}</strong>
                                                    )}{" "}
                                                    {room.price_per_months ? "per month" : "per night"}
                                                </p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="room_card_single" key={index}>
                                    <div className='room-card card border-0'>
                                        <div className='card-img-top'>
                                            <OwlCarousel className="room_card_image_slider owl-carousel owl-theme" {...options}>
                                                <div className="item">
                                                    <Link to={`/room/${room.id}/details`}>
                                                        <img
                                                            src={room.featured_image[0].data_url}
                                                            alt={room.title}
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="item">
                                                    <Link to={`/room/${room.id}/details`}>
                                                        <img
                                                            src={room.featured_image[0].data_url}
                                                            alt={room.title}
                                                        />
                                                    </Link>
                                                </div>
                                            </OwlCarousel>
                                        </div>
                                        <div className="card-body room_card_details p-0">
                                            <Link to={`/room/${room.id}/details`}>
                                                <div className='room_title'>
                                                    <span>{room.title}</span>
                                                    <span className='room_rating'><i className="fa fa-star"></i> {this.generateRandomRating()}</span>
                                                </div>
                                                <p className='text-muted room_address'>{room.apartment.address}</p>
                                                <p className='room_price m-0'>
                                                    {room.price_per_months ? (
                                                        <strong className="text-decoration-underline">৳{room.price_per_months}</strong>
                                                    ) : (
                                                        <strong className="text-decoration-underline">৳{room.price_per_night}</strong>
                                                    )}{" "}
                                                    {room.price_per_months ? "per month" : "per night"}
                                                </p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="room_card_single" key={index}>
                                    <div className='room-card card border-0'>
                                        <div className='card-img-top'>
                                            <OwlCarousel className="room_card_image_slider owl-carousel owl-theme" {...options}>
                                                <div className="item">
                                                    <Link to={`/room/${room.id}/details`}>
                                                        <img
                                                            src={room.featured_image[0].data_url}
                                                            alt={room.title}
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="item">
                                                    <Link to={`/room/${room.id}/details`}>
                                                        <img
                                                            src={room.featured_image[0].data_url}
                                                            alt={room.title}
                                                        />
                                                    </Link>
                                                </div>
                                            </OwlCarousel>
                                        </div>
                                        <div className="card-body room_card_details p-0">
                                            <Link to={`/room/${room.id}/details`}>
                                                <div className='room_title'>
                                                    <span>{room.title}</span>
                                                    <span className='room_rating'><i className="fa fa-star"></i> {this.generateRandomRating()}</span>
                                                </div>
                                                <p className='text-muted room_address'>{room.apartment.address}</p>
                                                <p className='room_price m-0'>
                                                    {room.price_per_months ? (
                                                        <strong className="text-decoration-underline">৳{room.price_per_months}</strong>
                                                    ) : (
                                                        <strong className="text-decoration-underline">৳{room.price_per_night}</strong>
                                                    )}{" "}
                                                    {room.price_per_months ? "per month" : "per night"}
                                                </p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))
                    ) : (
                        <>
                            <div className="room_card_single">
                                <div className="to-ho-hotel-con">
                                    <div className="to-ho-hotel-con-1">
                                        <img src="/assets/images/room/4.jpg" alt="" />
                                    </div>
                                    <div className="to-ho-hotel-con-23">
                                        <div className="to-ho-hotel-con-2"> <a href="room-details.html">
                                            <h4>No Room</h4>
                                        </a> </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room_card_single">
                                <div className="to-ho-hotel-con">
                                    <div className="to-ho-hotel-con-1">
                                        <img src="/assets/images/room/4.jpg" alt="" />
                                    </div>
                                    <div className="to-ho-hotel-con-23">
                                        <div className="to-ho-hotel-con-2"> <a href="room-details.html">
                                            <h4>No Room</h4>
                                        </a> </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room_card_single">
                                <div className="to-ho-hotel-con">
                                    <div className="to-ho-hotel-con-1">
                                        <img src="/assets/images/room/4.jpg" alt="" />
                                    </div>
                                    <div className="to-ho-hotel-con-23">
                                        <div className="to-ho-hotel-con-2"> <a href="room-details.html">
                                            <h4>No Room</h4>
                                        </a> </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room_card_single">
                                <div className="to-ho-hotel-con">
                                    <div className="to-ho-hotel-con-1">
                                        <img src="/assets/images/room/4.jpg" alt="" />
                                    </div>
                                    <div className="to-ho-hotel-con-23">
                                        <div className="to-ho-hotel-con-2"> <a href="room-details.html">
                                            <h4>No Room</h4>
                                        </a> </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room_card_single">
                                <div className="to-ho-hotel-con">
                                    <div className="to-ho-hotel-con-1">
                                        <img src="/assets/images/room/4.jpg" alt="" />
                                    </div>
                                    <div className="to-ho-hotel-con-23">
                                        <div className="to-ho-hotel-con-2"> <a href="room-details.html">
                                            <h4>No Room</h4>
                                        </a> </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room_card_single">
                                <div className="to-ho-hotel-con">
                                    <div className="to-ho-hotel-con-1">
                                        <img src="/assets/images/room/4.jpg" alt="" />
                                    </div>
                                    <div className="to-ho-hotel-con-23">
                                        <div className="to-ho-hotel-con-2"> <a href="room-details.html">
                                            <h4>No Room</h4>
                                        </a> </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    }
}

export default RoomGrid;
