import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SearchForm from '../partials/SearchForm';
import { useModal } from '../../_context/ModalContext';
import SessionHelper from '../../session/SessionHelper';
import { useUser } from '../../_context/UserContext';

const Header = () => {
    const { openModal } = useModal();
    const { logout } = useUser();
    const location = useLocation();

    const isHomePage = location.pathname === '/';
    const [isVisible, setIsVisible] = useState(isHomePage);

    useEffect(() => {
        setIsVisible(location.pathname === '/');
    }, [location.pathname]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsVisible(false);
            } else if (isHomePage) {
                setIsVisible(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isHomePage]);

    const showSearchPanel = () => {
        if (!isVisible) setIsVisible(true);
    };


    return (
        <>
            <header>
                <nav className="navbar navbar-light bg-light fixed-top">
                    <div className="container-fluid">
                        <div className="col-sm-11 mx-auto">
                            <div className="d-flex justify-content-between align-items-center w-100">
                                <div className="navbar-header">
                                    <Link to="/" className="navbar-brand logo">
                                        <img src="/assets/images/logo1.png" alt="Logo" />
                                    </Link>
                                </div>
                                <div className="search_panel">
                                    <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                                        <li className="nav-item" onClick={showSearchPanel}>
                                            <a className="nav-link active" id="permanent-tab" data-toggle="tab" href="#permanent"
                                                role="tab" aria-controls="permanent" aria-selected="true">Permanent</a>
                                        </li>
                                        <li className="nav-item" onClick={showSearchPanel}>
                                            <a className="nav-link" id="guest-tab" data-toggle="tab" href="#guest" role="tab"
                                                aria-controls="guest" aria-selected="false">Guest</a>
                                        </li>
                                    </ul>
                                </div>
                                <ul className="nav navbar-nav">
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fa fa-bars mr-1" aria-hidden="true"></i>
                                            <img src="/assets/images/profile.png" width="30px" alt="Profile" />
                                        </Link>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            {SessionHelper.GetAuthSession() !== null ? (
                                                <>
                                                    <Link className="dropdown-item" to='/dashboard'>My Account</Link>
                                                    <Link className="dropdown-item" to="" onClick={logout}>Logout</Link>
                                                </>
                                            ) : (
                                                <>
                                                    <Link className="dropdown-item" onClick={() => openModal('register')}>Register</Link>
                                                    <Link className="dropdown-item" onClick={() => openModal('login')}>Log In</Link>
                                                </>
                                            )}
                                            <div className="dropdown-divider"></div>
                                            <Link className="dropdown-item" to='/'>Home</Link>
                                            <Link className="dropdown-item" to='/about-us'>About Us</Link>
                                            <Link to="/contact-us" className="dropdown-item">Contact Us</Link>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                <SearchForm isVisible={isVisible} />
            </header>
        </>
    );
}

export default Header;
