import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import axios from 'axios';
import SocialLogin from "./SocialLogin";

const ForgotPassword = ({ closeModal, setModalMode }) => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        errors: [],
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        const url = "/api/login";
        try {
            const response = await axios.post(url, formData);
            alert(response.data.message); // Handle success
            closeModal();
        } catch (err) {
            setError(err.response?.data?.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
                <div className="log-in-pop-left">
                    <div style={{ 'position':'relative', 'zIndex':'10' }}> 
                        <h1>Hello...</h1>
                        <p>Don't have an account? Create your account. It's take less then a minutes</p>
                        <h4>Login with social media</h4>
                        <ul className='list-unstyled'>
                            <SocialLogin setModalMode={setModalMode}/>
                        </ul>
                    </div>
                </div>
                <div className="log-in-pop-right">
                    <a href="#" className="pop-close" onClick={closeModal}><img style={{ width: '15px' }} src="/assets/images/cancel.png" alt="" />
                    </a>
                    <h4>Forgot password</h4>
                    <p>Don't have an account? Create your account. It's take less then a minutes</p>
                    <div className='form-group'>
                        <div className="input-field">
                            <input type="text" name='username' id="forget_username" className="form-control form-control-lg" placeholder='' />
                            <label htmlFor='forget_username' className='input-field-label'>User name or email id</label>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="input-field">
                            <Button variant="primary" type="submit" disabled={loading}>
                                {loading ? "Processing..." : "Submit"}
                            </Button>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className="input-field s12"> <a href="#" onClick={() => setModalMode("login")}>Are you a already member ? Login</a> | <a href="#"
                            onClick={() => setModalMode("register")}>Create a new
                            account</a> </div>
                    </div>
                </div>
            </Form>
        </>
    );
}


export default ForgotPassword;
