import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../../_context/UserContext';
import Sidebar from './partials/Sidebar';
import WithRouter from '../../_utility/WithRouter';
import axios from 'axios';

const Dashboard = () => {
    const { logout, authUser } = useUser();
    const [bookings, setBookings] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get('/sanctum/csrf-cookie').then(() => {
            axios.post(`/api/booking/${authUser?.user_id}/lists`)
                .then(res => {
                    const data = res.data.data;
                    console.log(data);
                    setBookings(data);
                })
                .catch(error => {
                    console.error("Error fetching rooms:", error);
                });
        });
    }, [authUser?.user_id]);
    return (
        <>
            <section>
                <div className="dashboard mt-0 mb-0">
                    <div className='row'>
                        <div className="col-lg-3 col-sm-12 db-left p-0">
                            <Sidebar />
                        </div>
                        <div className="col-lg-9 col-sm-12 db-cent p-0">
                            <div className="db-cent-1">
                                <p>{authUser?.detail?.name},</p>
                                <h4>Welcome to your dashboard</h4> </div>
                            <div className="db-cent-3">
                                <div className="db-cent-table db-com-table">
                                    <div className="db-title">
                                        <h3><img src="images/icon/dbc5.png" alt="" /> My Bookings</h3>
                                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form</p>
                                    </div>
                                    <table className="table bordered responsive-table">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Room</th>
                                                <th>Occupancy Type</th>
                                                <th>Arrival</th>
                                                <th>Departure</th>
                                                <th>Total Seats</th>
                                                <th>Adults</th>
                                                <th>Children</th>
                                                <th>Total Nights</th>
                                                <th>Total Amount</th>
                                                <th>Due Amount</th>
                                                <th>Status</th>
                                                <th>Created At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bookings && bookings.length > 0 ? (
                                                bookings.map((booking, index) => (
                                                    <>
                                                        <tr key={index}>
                                                            <td>{index+1}</td>
                                                            <td>{booking.reference_room_id}</td>
                                                            <td>{booking.occupancy_type}</td>
                                                            <td>{booking.check_in_date}</td>
                                                            <td>{booking.check_out_date}</td>
                                                            <td>{booking.number_of_seats}</td>
                                                            <td>{booking.total_adults}</td>
                                                            <td>{booking.total_children}</td>
                                                            <td>{booking.total_nights}</td>
                                                            <td>{booking.total_amount}</td>
                                                            <td>{booking.due_amount}</td>
                                                            <td>{booking.status}</td>
                                                            <td>{booking.created_at}</td>
                                                        </tr>
                                                    </>
                                                ))
                                            ) : (
                                                <>
                                                </>
                                            )}


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default WithRouter(Dashboard);
