import React from 'react';
import { DatePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const SingleDatePicker = ({date, setDate}) => {

    const handleDateChange = (value) => {
        setDate(value);
    };

    return (
        <>
            <DatePicker 
                placeholder="Check In" 
                className="search_date_select" 
                value={date}
                onChange={handleDateChange}
            />
        </>
    );
};

export default SingleDatePicker;
