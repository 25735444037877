const OccupantCard = ({ occupant, onClick }) => {
  return (
    <div className="card mt-2">
      <div className="card-body p-2">
        <p className="card-text">
          <strong className="d-inline-block">Name:</strong> <span className="d-inline-block">{occupant.name}</span>
        </p>
        <p className="card-text">
          <strong className="d-inline-block">Email:</strong> <span className="d-inline-block">{occupant.email}</span>
        </p>
        <p className="card-text">
          <strong className="d-inline-block">Contact Number:</strong> <span className="d-inline-block">{occupant.contact_number}</span>
        </p>
        <p className="card-text">
          <strong className="d-inline-block">Gender:</strong> <span className="d-inline-block">{occupant.gender}</span>
        </p>
        <p className="card-text">
          <strong className="d-inline-block">Date of Birth:</strong> <span className="d-inline-block">{occupant.dob}</span>
        </p>
        <p className="card-text">
          <strong className="d-inline-block">Nationality:</strong> <span className="d-inline-block">{occupant.nationality}</span>
        </p>
        <p className="card-text">
          <strong className="d-inline-block">Identification:</strong> <span className="d-inline-block">{occupant.identification_type} {occupant.identification_number} </span>
        </p>
        <p className="card-text">
          <strong className="d-inline-block">Address:</strong> <span className="d-inline-block">{occupant.address}</span>
        </p>
      </div>
      <div className="card-footer p-0 border border-primary">
        <button
          className="btn btn-primary btn-block rounded-0"
          onClick={onClick}
        >
          {occupant ? "Edit Occupant" : "Add Occupant"}
        </button>
      </div>
    </div>
  );
};

export default OccupantCard;  // Default export
