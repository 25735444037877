import React from "react";
import { Modal} from "react-bootstrap";
import Login from "../auth/partials/Login";
import Register from "../auth/partials/Register";
import ForgotPassword from "../auth/partials/ForgotPassword";


const ModalBody = ({ showModal, closeModal, modalMode, setModalMode }) => {

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            dialogClassName="modal-dialog modal-dialog-centered modal-lg"
        >
                {modalMode === "register" && (
                    <Register
                        closeModal={closeModal}
                        setModalMode={setModalMode}
                    />
                )}
                {modalMode === "login" && (
                    <Login
                    closeModal={closeModal}
                    setModalMode={setModalMode}
                    />
                )}
                {modalMode === "forgot-password" && (
                    <ForgotPassword
                    closeModal={closeModal}
                    setModalMode={setModalMode}
                    />
                )}
        </Modal>
    );
};

export default ModalBody;
