import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/bootstrap/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import './assets/plugins/owlCarousel/dist/assets/owl.carousel.min.css';
import './assets/plugins/owlCarousel/dist/assets/owl.theme.default.min.css';
import './assets/plugins/daterangepicker/daterangepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import reportWebVitals from './reportWebVitals';
import { ModalProvider } from './_context/ModalContext';
import { UserProvider } from './_context/UserContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ModalProvider>
      <UserProvider >
        <App />
      </UserProvider>
    </ModalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
