import React, { Component } from 'react';
import FilterSection from '../component/partials/FilterSection';
import RoomGrid from '../component/partials/RoomGrid';

class Home extends Component {
    render() {
        return (
            <>
                <FilterSection />

                <section>
                    <div className="container-fluid pad-bot-40">
                        <div className="col-sm-11 mx-auto">
                            <div className="to-ho-hotel">
                                    <RoomGrid />
                                    <div className="col-md-12 text-center">
                                        <a href='#' className="btn btn-info btn-lg">Load More</a>
                                    </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Home;
